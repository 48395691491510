import React, { Component} from 'react'
import {
	Container,
	Header,
	Pagination,
} from 'semantic-ui-react'
import {navigate, graphql} from 'gatsby'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import BlogList from '../components/BlogList'
import * as styles from './styles/pages.module.css'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'


export default class Howto extends Component {
	state = {
		currentPage:1,
		limit:6,
		itemsPerRow: 2,
		paginationRange:null,
		timeOut:null
	}

	componentDidMount() {
		this.handleParameters();
		this.handler();

		window.addEventListener('resize', this.resizeTimerFunc);
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	handler = () => {
		//computer
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				paginationRange:4,
				itemsPerRow:3
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				paginationRange:2,
				itemsPerRow:2
			})
		} else {  //mobile
			this.setState({
				paginationRange:1,
				itemsPerRow:1
			})
		}
	}

	handleParameters = () => {
		const {location} = this.props
		if(location.search) {
			const parameters = location.search.split("&")
			/*important checks if current result is already matched,
			 *without this if statement, there'll be an infinite loop
			 */
			for( let i =0; i < parameters.length; i++) {
				if(parameters[i].split("=")[0] === "page" || parameters[i].split("=")[0] === "?page") {
					this.setState({ currentPage:parameters[i].split("=")[1] })
				}else {
					//unplanned parameters
				}
			}

		}
	}

	render() {
		const data = this.props.data.allMarkdownRemark.edges
		const {
			currentPage,
			limit,
			paginationRange
		} = this.state
		let slicedPage
		let length = 0
		let totalPages;

		slicedPage = data

		length = slicedPage.length
		totalPages=Math.ceil(length/limit)
		slicedPage = slicedPage.slice( (currentPage-1)*limit, limit*currentPage)

		const convertedSlicedPages = slicedPage.map((item)=>{
			return {
				squareImageHref:item.node.frontmatter.thumbnail,
				name:item.node.frontmatter.title,
				date:item.node.frontmatter.date,
				summary:item.node.frontmatter.excerpt,
				url:`howto/${item.node.frontmatter.slug}`
			}
		})

		const location = this.props.location
		return (
			<Layout location={location} backButton={true}>
				<Seo
					image={"https://cdn.ngnw.ph/images/blogtype/images/how-tos.jpg"} imageWidth={1200} imageHeight={480}
					canonicalpath="/howto"
					title={"How-To Guides"}
					description="Learn how to run you business with these guides. From operating equipment to administrative tasks, get them here."
				/>
				<Container className={styles.container}>
					<Header className={styles.superHeader} >
						<h1>How-To Guides</h1>
					</Header>
					<BlogList
						items={convertedSlicedPages}
						computerShown={3}
						tabletShown={2}
						mobileShown={1}
						initialShown={2}
					/>
					{totalPages > 1 && (
						<React.Fragment>
							<br/>
							<Pagination
								activePage={currentPage}
								onPageChange={(e, {activePage})=>{
									this.setState({
										currentPage:activePage
									})
									navigate(`${location.pathname}?page=${activePage}`)
								}}
								ellipsisItem={null}
								boundaryRange={null}
								siblingRange={paginationRange}
								totalPages={totalPages}
								pageItem={{
									className:styles.paginationItem
								}}
								firstItem={{
									className:styles.paginationItem,
									content:"<<"
								}}
								lastItem={{
									className:styles.paginationItem,
									content:">>"
								}}
								nextItem={{
									className:styles.paginationItem,
									content:">"
								}}
								prevItem={{
									className:styles.paginationItem,
									content:"<"
								}}
							/>
						</React.Fragment>
					)}
				</Container>
			</Layout>
		)
	}
}

export const data = graphql`
	query HowtoQuery {
		allMarkdownRemark(
				filter: { frontmatter: { tags: { in: ["howto"] } } }
				sort: {order:[ASC, DESC], fields:[frontmatter___order, frontmatter___date]}
			) {
			edges {
				node {
					frontmatter {
						slug
						title
						excerpt
						thumbnail
						date(formatString: "MMMM DD, YYYY")
					}
				}
			}
		}
	}
`